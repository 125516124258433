<template>
  <div>
    <div>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="学生姓名" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.name"
            placeholder="请输入学生姓名"
            @input="reloadCompanExamineeData"
          ></el-input>
        </el-form-item>

        <el-form-item label="性别" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.gender"
            placeholder="请选择性别"
            @change="reloadCompanExamineeData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in genderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="毕业年份" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.graduationYear"
            placeholder="请选择毕业年份"
            @change="reloadCompanExamineeData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in graduationYearOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.schoolCode"
            placeholder="请选择学校"
            @change="companSchoolChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in companSchoolList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="班级" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.citySchoolClassId"
            placeholder="请选择班级"
            @change="reloadCompanExamineeData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in companSchoolClassList"
              :key="item.schoolClassId"
              :label="item.name"
              :value="item.schoolClassId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="reloadCompanExamineeData"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        v-loading="loading"
        border
        :data="examineeDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="学生姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="code"
          label="学号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="areaName"
          label="区县"
          show-overflow-tooltip
          :min-width="40"
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          label="学校"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="gender"
          label="性别"
          show-overflow-tooltip
          :min-width="30"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gender == 0">女</span>
            <span v-else-if="scope.row.gender == 1">男</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="graduationYear"
          label="毕业年份"
          show-overflow-tooltip
          :min-width="40"
        ></el-table-column>
        <el-table-column
          prop="citySchoolClassName"
          label="班级"
          show-overflow-tooltip
          :min-width="40"
        ></el-table-column>
        <el-table-column
          prop="idCardType"
          label="证件类型"
          show-overflow-tooltip
          :min-width="180"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.idCardType == 1">身份证</span>
            <span v-else-if="scope.row.idCardType == 2"
              >港澳居民往来内地通行证</span
            >
            <span v-else-if="scope.row.idCardType == 3"
              >台湾居民往来内地通行证</span
            >
            <span v-else-if="scope.row.idCardType == 4">护照</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="idCard"
          label="证件号码"
          show-overflow-tooltip
          :min-width="180"
        ></el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParam.pageIndex"
        :page-size="pageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageParam.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getCompanExamineeDataApi } from '@/api/schoolManufacturer/examineeData.js'
import {
  getCompanSchoolListApi,
  getCompanSchoolClassApi,
} from '@/api/schoolManufacturer/common.js'
import { isEmpty } from '@/utils/util'
export default {
  name: 'examineeData',
  data() {
    return {
      queryParam: {
        name: '',
        gender: '',
        graduationYear: '',
        schoolCode: '',
        citySchoolClassId: '',
      },
      loading: false,
      examineeDataList: [],
      genderOptions: [],
      graduationYearOptions: [],
      citySchoolClassOptions: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      companSchoolList: [],
      companSchoolClassList: [],
    }
  },
  mounted() {
    this.renderSelect()
    this.init()
  },
  methods: {
    renderSelect() {
      this.genderOptions = this.$Global.globalGenderOptions
      this.graduationYearOptions = this.$Global.graduationYearList
      this.getCompanSchoolList()
    },
    init() {
      this.getCompanExamineeData()
    },
    //获取考生数据-分页
    getCompanExamineeData() {
      this.loading = true
      getCompanExamineeDataApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.examineeDataList = []
            this.examineeDataList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    //获取筛选学校列表
    getCompanSchoolList() {
      getCompanSchoolListApi().then((res) => {
        if (res.success) {
          this.companSchoolList = []
          this.companSchoolList = res.data
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    companSchoolChange(val) {
      if (isEmpty(val)) {
        this.queryParam.citySchoolClassId = ''
        this.companSchoolClassList = []
      } else {
        this.getCompanSchoolClass(val)
      }
      this.reloadCompanExamineeData()
    },
    //获取筛选班级列表
    getCompanSchoolClass(val) {
      getCompanSchoolClassApi(val).then((res) => {
        this.dialogVisible = true
        if (res.success) {
          this.companSchoolClassList = []
          this.companSchoolClassList = res.data
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    reloadCompanExamineeData() {
      this.queryParam.pageIndex = 1
      this.getCompanExamineeData()
    },
    refresh() {
      this.queryParam.name = ''
      this.queryParam.gender = ''
      this.queryParam.graduationYear = ''
      this.queryParam.schoolCode = ''
      this.queryParam.citySchoolClassId = ''
      this.companSchoolClassList = []
      this.reloadCompanExamineeData()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanExamineeData()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanExamineeData()
    },
  },
}
</script>

<style></style>
