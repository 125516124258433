import { request, noTimeOutReq } from '@/utils/request.js'

//获取基础数据
export function getCompanBaseDataApi () {
  return noTimeOutReq({
    url: `/company/join/base/get`,
    method: 'post',
  })
}

// 同步基础数据
export function syncCompanyBaseDataApi () {
  return noTimeOutReq({
    url: `/company/join/base/sync`,
    method: 'post',
  })
}

//获取最后一次基础数据操作时间
export function getCompanyBaseLastTimeApi () {
  return request({
    url: `/company/join/base/last/time/get`,
    method: 'post',
  })
}

//获取市级平台配置
export function getCompanCityConfigApi () {
  return request({
    url: `/company/join/base/city/get`,
    method: 'post',
  })
}


//保存市级平台配置
export function saveCompanCityConfigApi (data) {
  return request({
    url: `/company/join/base/city/update`,
    method: 'post',
    data: data
  })
}

// 导入基础数据
export function importExcelData (data) {
  return noTimeOutReq({
    url: `/company/join/base/data/import`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}