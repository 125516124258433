<template>
  <div>
    <div>
      <el-button v-throttle type="primary" size="medium" @click="bindCityMsetp"
        >绑定市级平台</el-button
      >
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="getCompanBaseData"
        >获取基础数据</el-button
      >
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="syncCompanyBaseData"
        >确认同步</el-button
      >
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        >导入基础数据</el-button
      >
    </div>
    <!-- 导入框 -->
    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>

    <div style="margin-top: 20px; margin-bottom: 10px">
      <span>
        上次请求时间:
        <span v-if="companyBaseLastTime.gmtBaseLastRequest">{{
          companyBaseLastTime.gmtBaseLastRequest | dateFormat
        }}</span>
      </span>
      <span style="margin-left: 300px">
        上次同步:
        <span v-if="companyBaseLastTime.gmtBaseLastConfirm">{{
          companyBaseLastTime.gmtBaseLastConfirm | dateFormat
        }}</span>
      </span>
    </div>
    <div>
      <el-tabs v-model="active_tab" @tab-click="handleClick">
        <el-tab-pane
          v-for="pane in paneList"
          :key="pane.index + keyF5"
          :label="pane.label"
          :name="pane.name"
          :lazy="pane.index != 0"
        >
          <placeData ref="place" v-if="pane.index == 0"></placeData>
          <schoolData ref="school" v-else-if="pane.index == 1"></schoolData>
          <examineeData
            ref="examinee"
            v-else-if="pane.index == 2"
          ></examineeData>
          <teacherData ref="teacher" v-else-if="pane.index == 3"></teacherData>
          <questionData
            ref="question"
            v-else-if="pane.index == 4"
          ></questionData>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      title="绑定市级平台"
      :visible.sync="dialogVisible"
      width="30%"
      @close="close"
    >
      <el-form
        :model="companCityConfig"
        label-width="100px"
        :rules="rules"
        ref="companCityRef"
      >
        <el-form-item label="市平台地址" prop="cityPlatformIp">
          <el-input
            maxlength="50"
            v-model.trim="companCityConfig.cityPlatformIp"
            clearable
            placeholder="http://ip:port"
          ></el-input>
        </el-form-item>
        <el-form-item label="厂商编码" prop="companyCode">
          <el-input
            maxlength="50"
            v-model.trim="companCityConfig.companyCode"
            clearable
            placeholder="厂商编码"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="校平台编码" prop="companySchoolCode">
          <el-input
            maxlength="50"
            v-model.trim="companCityConfig.companySchoolCode"
            clearable
            placeholder="校平台编码"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="key" prop="schoolKey">
          <el-input
            v-model.trim="companCityConfig.schoolKey"
            clearable
            placeholder="校平台key"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="密钥" prop="schoolSecret">
          <el-input
            type="textarea"
            v-model.trim="companCityConfig.schoolSecret"
            :rows="8"
            clearable
            placeholder="密钥"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-throttle
          type="primary"
          @click="saveCompanCityConfig('companCityRef')"
          >保存</el-button
        >
        <el-button v-throttle @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import placeData from './component/placeData.vue'
import schoolData from './component/schoolData.vue'
import examineeData from './component/examineeData.vue'
import teacherData from './component/teacherData.vue'
import questionData from './component/questionData.vue'
import examinationRoomData from './component/examinationRoomData.vue'
import {
  getCompanBaseDataApi,
  getCompanyBaseLastTimeApi,
  getCompanCityConfigApi,
  saveCompanCityConfigApi,
  syncCompanyBaseDataApi,
  importExcelData,
} from '@/api/schoolManufacturer/baseData.js'
import { isEmpty } from '@/utils/util.js'
export default {
  components: {
    placeData,
    schoolData,
    examineeData,
    teacherData,
    questionData,
    examinationRoomData,
  },
  data() {
    return {
      // 导入
      uploadMsg: '',
      uploadLoading: false,
      keyF5: false,
      active_tab: 'place',
      paneList: [
        { label: '考点数据', name: 'place', index: 0 },
        { label: '学校数据', name: 'school', index: 1 },
        { label: '学生数据', name: 'examinee', index: 2 },
        { label: '老师数据', name: 'teacher', index: 3 },
        { label: '考题数据', name: 'question', index: 4 },
      ],
      // isShow: false
      dialogVisible: false,
      companCityConfig: {
        cityPlatformId: 0,
        cityPlatformIp: '',
        schoolKey: '',
        schoolSecret: '',
      },
      companyBaseLastTime: {
        gmtBaseLastConfirm: '',
        gmtBaseLastRequest: '',
      },
      rules: {
        cityPlatformIp: [
          {
            required: true,
            message: '市平台地址不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        companyCode: [
          {
            required: true,
            message: '厂商编码不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        companySchoolCode: [
          {
            required: true,
            message: '校平台编码不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        schoolKey: [
          {
            required: true,
            message: '校平台key不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        schoolSecret: [
          {
            required: true,
            message: '密钥不能为空',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  created() {},
  mounted() {
    this.getCompanyBaseLastTime()
    console.log(this.active_tab)
  },
  methods: {
    bindCityMsetp() {
      this.getCompanCityConfig()
      this.dialogVisible = true
    },
    //获取最后一次基础数据操作时间
    getCompanyBaseLastTime() {
      getCompanyBaseLastTimeApi().then((res) => {
        if (res.success) {
          if (!isEmpty(res.data.gmtBaseLastConfirm)) {
            this.companyBaseLastTime.gmtBaseLastConfirm =
              res.data.gmtBaseLastConfirm
          }
          if (!isEmpty(res.data.gmtBaseLastRequest)) {
            this.companyBaseLastTime.gmtBaseLastRequest =
              res.data.gmtBaseLastRequest
          }
        } else {
          this.companyBaseLastTime.gmtBaseLastConfirm = ''
          this.companyBaseLastTime.gmtBaseLastRequest = ''
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    //获取市级平台配置
    getCompanCityConfig() {
      getCompanCityConfigApi().then((res) => {
        if (res.success) {
          if (!isEmpty(res.data)) {
            this.companCityConfig = res.data
          }
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    //获取基础数据
    getCompanBaseData() {
      const loading = this.globalLoading('正在获取基础数据...')
      getCompanBaseDataApi()
        .then((res) => {
          loading.close()
          if (res.success) {
            this.keyF5 = !this.keyF5
            this.msgSuccess('获取数据成功')
            this.$refs[this.active_tab][0].init()
            this.getCompanyBaseLastTime()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //同步基础数据
    syncCompanyBaseData() {
      const loading = this.globalLoading('正在同步...')
      syncCompanyBaseDataApi()
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('同步成功')
            this.getCompanyBaseLastTime()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //保存市级平台配置
    saveCompanCityConfig(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveCompanCityConfigApi(this.companCityConfig).then((res) => {
            if (res.success) {
              this.msgSuccess('保存成功')
              this.dialogVisible = false
            } else {
              if (res.code != 500) {
                this.msgWarning(res.msg)
              }
            }
          })
        } else {
          return false
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    close() {
      this.companCityConfig = {}
      this.dialogVisible = false
    },
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      importExcelData(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.success) {
            this.$message.success('导入成功')
            for (let index = 0; index < this.paneList.length; index++) {
              const element = this.paneList[index].name
              this.$refs[element][0].init()
            }
            // this.$message({
            //   showClose: true,
            //   message: '导入成功',
            //   type: 'success',
            //   duration: 10000,
            // })
            this.getCompanyBaseLastTime()
            this.$refs.uploadExcelCom.handleClose()
          } else {
            // this.$message.warning(res.msg)
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
  },
}
</script>

<style></style>
