<template>
  <div>
    <div>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="老师姓名" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.name"
            placeholder="请输入老师姓名"
            @input="reloadCompanExamineeData"
          ></el-input>
        </el-form-item>

        <el-form-item label="账号" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.account"
            placeholder="请输入账号"
            @input="reloadCompanExamineeData"
          ></el-input>
        </el-form-item>

        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择科目"
            @change="reloadCompanExamineeData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <!--  <el-form-item label="角色">
          <el-select
            v-model="queryParam.gender"
            placeholder="请选择角色"
            @change="reloadCompanExamineeData"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>-->

        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.status"
            placeholder="请选择状态"
            @change="reloadCompanExamineeData"
          >
            <el-option label="全部" value></el-option>
            <el-option label="禁用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="reloadCompanExamineeData"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        v-loading="loading"
        border
        :data="teacherDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="nickName"
          label="老师姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="userCode"
          label="用户编码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="areaName"
          label="区县"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          label="学校"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="subjectType" label="科目" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.subjectType == 1">物理</span>
            <span v-else-if="scope.row.subjectType == 2">生物</span>
            <span v-else-if="scope.row.subjectType == 3">化学</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="account" label="角色" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">禁用</span>
            <span v-else-if="scope.row.status == 1">启用</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParam.pageIndex"
        :page-size="pageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageParam.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getCompanTeacherDataApi } from '@/api/schoolManufacturer/teacherData.js'
export default {
  name: 'teacherData',
  data() {
    return {
      queryParam: {
        name: '',
        account: '',
        subjectType: '',
        gender: 'shanghai',
        status: '',
      },
      loading: false,
      teacherDataList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      subjectOptions: [],
    }
  },
  mounted() {
    this.renderSelect()
    this.init()
  },
  methods: {
    renderSelect() {
      this.subjectOptions = this.$Global.globalSubjectOptions
    },
    init() {
      this.getCompanTeacherData()
    },
    //获取教师数据-分页
    getCompanTeacherData() {
      this.loading = true
      getCompanTeacherDataApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.teacherDataList = []
            this.teacherDataList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    reloadCompanExamineeData() {
      this.queryParam.pageIndex = 1
      this.getCompanTeacherData()
    },
    refresh() {
      this.queryParam.name = ''
      this.queryParam.account = ''
      this.queryParam.subjectType = ''
      this.queryParam.gender = ''
      this.queryParam.status = ''
      this.reloadCompanExamineeData()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanTeacherData()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanTeacherData()
    },
  },
}
</script>

<style></style>
