<template>
  <div>
    <el-row :gutter="showSeatLayout ? 20 : 0">
      <el-col :span="showSeatLayout ? 18 : 24">
        <div>
          <el-form :inline="true" :model="queryParam">
            <el-form-item label="考场名称">
              <el-input
                v-model="queryParam.name"
                placeholder="请输入考场名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="代码">
              <el-select v-model="queryParam.gender" placeholder="请输入代码">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="科目">
              <el-select v-model="queryParam.gender" placeholder="请选择科目">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button
                v-throttle
                type="primary"
                icon="el-icon-refresh"
                @click="refresh"
                >刷新</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div>
          <el-table
            v-loading="loading"
            stripe
            border
            :data="list"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="nickName"
              label="考场名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="科目"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="代码"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="座位数"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="座位布局"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="媒体流"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="备注"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </div>

        <div style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageParam.pageIndex"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-col>

      <el-col :span="showSeatLayout ? 6 : 0">
        <div>坤你太美</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'examinationRoomData',
  data() {
    return {
      queryParam: {
        name: '',
        gender: 'shanghai',
      },
      loading: false,
      list: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      //显示座位布局
      showSeatLayout: false,
    }
  },
  methods: {
    refresh() {},
    handleCurrentChange(val) {},
    handleSizeChange(val) {},
  },
}
</script>

<style></style>
